import React from "react"
import { graphql } from "gatsby"
import * as styles from "../../styles/page/second.module.scss"

// 共通コンポーネント
import Meta from "../../components/layouts/meta"
import Layout from "../../components/layouts/default"
import BreadCrumb from "../../components/layouts/bread-crumb"

// markup
const IndexPage = ({data}) => {
  const markdownRemark = data.markdownRemark
  const { frontmatter } = markdownRemark
  const { title, description, slug } = frontmatter

  return (
    <Layout>
      <Meta 
        title={title}
        description={description}
        path={slug}
      />

      <BreadCrumb
        currentTitle={title}
      />

      <div className={styles.pageTitle}>
        <h1 className={`${styles.pageTitleHeading} mb-6`}>{title}</h1>
      </div>

      <main className={styles.pageContents}>
      <h2 className={styles.pageContentsHeading2}>個人情報の取得</h2>
      <p className="pb-1">国立研究開発法人新エネルギー・産業技術総合開発機構（以下「NEDO」という）では、NEDO Webサイト（以下「当サイト」という）で提供するサービス（メール配信、利用登録、各種お問い合わせの受付、資料請求）において利用者から個人を特定できる情報を取得することがあります。取得の際にはその利用目的を明示し、取得した個人情報は利用目的の範囲内で適切に取り扱います。</p>
      <p className="pb-2">なお、取得する個人情報は、当サイトの各サービスを提供するにあたり必要な項目で、例えば次のようなものがあります。</p>
      <ul className="pb-4">
        <li>氏名</li>
        <li>所属機関</li>
        <li>部署、役職</li>
        <li>住所、郵便番号</li>
        <li>電話番号、ファックス番号</li>
        <li>E-mail</li>
        <li>その他、サービス提供に不可欠な事項</li>
      </ul>

      <h2 className={styles.pageContentsHeading2}>個人情報の利用範囲</h2>
      <p className="pb-2">利用者から取得した個人情報は、あらかじめ明示した利用目的の範囲内で利用します。また、NEDO以外の第三者への提供は、以下の場合を除き、一切行いません。</p>
      <ul className="pb-4">
        <li>法令等に基づき要請された場合</li>
        <li>公開すべき場合でかつ、本人の同意がある場合</li>
        <li>守秘義務契約を結んだ業務委託先などへ利用目的範囲内で個人情報を提供する場合</li>
      </ul>
      
      <h2 className={styles.pageContentsHeading2}>収集する情報の範囲と利用目的</h2>
      <p className="pb-1">NEDOでは、インターネットドメイン名、IPアドレス、サイト内検索のクエリ情報、その他当サイト閲覧に係る情報（アクセスログ）を自動的に取得しています。なお、クッキー（サーバ側で利用者を識別するために、サーバから利用者のブラウザに送信され、利用者のコンピュータに蓄積させる情報）は、個人情報は一切含みません。</p>
      <p className="pb-4">取得した情報は当サイトのアクセスの傾向などの統計分析等に使用し、それらの結果を今後の当サイト運営の参考とするために使用します。</p>

      <h2 className={styles.pageContentsHeading2}>個人情報の管理</h2>
      <p className="pb-4">NEDOは、当サイト上で取得した個人情報について厳重に管理し、紛失・漏洩・悪用・改ざん・不正アクセス等の危険防止のために必要な処置を講じます。</p>

      <h2 className={styles.pageContentsHeading2}>プライバシーポリシーの変更</h2>
      <p className="pb-4">このプライバシーポリシーは予告なく変更することがあります。変更した際には当サイト上に掲示します。</p>

      <h2 className={styles.pageContentsHeading2}>EU一般データ保護規則（GDPR）について</h2>
      <p className="pb-2">2018年5月25日付けで「EU一般データ保護規則（GDPR）」が施行されたことに伴い、EEA加盟国内、すなわち、EU加盟国、アイスランド、リヒテンシュタイン、ノルウェーのいずれかに在住の方は、以下をご確認ください。</p>
      <ul className="pb-4">
        <li>当サイト上で取得した個人情報はそれぞれの業務に係わる用途のみに使用します。</li>
        <li>個人情報はNEDOが設置するサーバに保存されます。NEDOは利用者の個人情報を適切に管理します。</li>
        <li>個人情報を提供した者が、自らの個人情報へのアクセス、不正確な個人情報の修正、個人情報の正確性を検証中であるデータ加工の制限を要求した場合、これを受諾します。<br />
        なお、NEDOの個人情報の取り扱いに不満がある場合には、EEA加盟国の監督機関に苦情申し立てをすることができます。</li>
        <li>個人情報を提供した者を、上記について同意したものとして扱います。なお、個人情報を提供した者はこの同意をいつでも撤回する権利があり、この同意の撤回は、撤回前のデータ処理やデータ移転の適法性に影響を与えるものではありません。</li>
      </ul>

      <h2 className={styles.pageContentsHeading2}>お問い合わせ先</h2>
      <p className="pb-1">NEDO　広報部</p>
      <p className="text-right">最終更新日：2023年10月16日</p>
      </main>

    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        description
        slug
      }
    }
  }
`